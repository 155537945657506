export default [
  {
    title: 'dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'dashboard',
    action: 'view',
    path: '/',
  },
  {
    title: 'account_mgmt',
    icon: 'UsersIcon',
    children: [
      {
        title: 'account_overview',
        route: 'account-management.account-overview',
        resource: 'account_overview',
        action: 'view',
        path: '/account-management',
      },
      {
        title: 'sub_account_mgmt',
        route: 'account-management.sub-account-management',
        resource: 'sub_account',
        action: 'manage',
        path: '/account-management/sub-account',
      },
    ],
  },
  {
    title: 'report',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'game_history',
        route: 'report.game-history',
        resource: 'report',
        action: 'game_history',
        path: '/report/game-history',
      },
      {
        title: 'game_transactions',
        route: 'report.game-transactions',
        resource: 'report',
        action: 'game_transactions',
        path: '/report/game-transactions',
      },
      {
        title: 'win_lose',
        route: 'report.win-lose',
        resource: 'report',
        action: 'win_lose',
        path: '/report/win-lose',
      },
      {
        title: 'transactions',
        route: 'report.transactions',
        resource: 'report',
        action: 'transactions',
        path: '/report/transactions',
      },
      {
        title: 'balance',
        route: 'report.balance',
        resource: 'report',
        action: 'balance',
        path: '/report/balance',
      },
      {
        title: 'online_user',
        route: 'report.online-user',
        resource: 'report',
        action: 'online_user',
        path: '/report/online-user',
      },
    ],
  },
  {
    title: 'setting',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'set_limit',
        route: 'setting.set_limit',
        resource: 'setting',
        action: 'set_limit',
        path: '/setting/set-limit',
      },
      {
        title: 'log',
        route: 'setting.log',
        resource: 'setting',
        action: 'log',
        path: '/setting/log',
      },
      {
        title: 'change_password',
        route: 'setting.change-password',
        resource: 'setting',
        action: 'change_password',
        path: '/setting/change-password',
      },
    ],
  },
  {
    title: 'audit',
    icon: 'EyeIcon',
    children: [
      {
        title: 'audit',
        route: 'audit.audit',
        resource: 'audit',
        action: 'view',
        path: '/audit',
      },
      {
        title: 'ban_list',
        route: 'audit.ban_list',
        resource: 'audit',
        action: 'view',
        path: '/audit/ban-list',
      },
      {
        title: 'unsettled',
        route: 'audit.unsettled',
        resource: 'audit',
        action: 'view',
        path: '/audit/unsettled',
      },
    ],
  },
]
